<div class="container-fluid myProfile-table-container">
  <!-- Customer/Requestor/Purchaser -->
  <div *ngIf="updatedCutomers?.length && (isInternal == false)" data-backdrop="false">
    <app-table-template [columns]="columns" [tableData]="updatedCutomers" [isEdit]="isShowCheckBox"
      [allowPagination]="true" (checkBtnClickEvent)="updateCheckBtnClick($event)"
      [rowsPerPage]="10"></app-table-template>
  </div>
  <div *ngIf="(!updatedCutomers?.length) && (isInternal == false)" data-backdrop="false">
    <app-table-template [columns]="columns" [tableData]="updatedCutomers" [isEdit]="isShowCheckBox"
      [allowPagination]="true" (checkBtnClickEvent)="updateCheckBtnClick($event)"
      [rowsPerPage]="10"></app-table-template>
  </div>

  <!-- SGRE admin/SalesRep    -->
  <div *ngIf="updatedCutomers?.length &&(isInternal == true)" data-backdrop="false">
    <app-table-template [columns]="columns2" [tableData]="updatedCutomers" [isEdit]="isShowCheckBox"
      [allowPagination]="true" (checkBtnClickEvent)="updateCheckBtnClick($event)"
      [rowsPerPage]="10"></app-table-template>
  </div>
  <div *ngIf="(!updatedCutomers?.length) &&(isInternal == true)" data-backdrop="false">
    <app-table-template [columns]="columns2" [tableData]="updatedCutomers" [isEdit]="isShowCheckBox"
      [allowPagination]="true" (checkBtnClickEvent)="updateCheckBtnClick($event)"
      [rowsPerPage]="10"></app-table-template>
  </div>

  <!-- SGRE admin/SalesRep    -->
  <div *ngIf="(isInternal == true)&& isShowCheckBox" class="row mb-4 align-items-center">
    <div class="col-md-3">
      <div class="card flex justify-content-center">
        <p-dropdown [options]="entityList" [(ngModel)]="selectedCity" optionLabel="name" [filter]="true" filterBy="name"
          [showClear]="selectedCity ?true:false" placeholder="{{'myProfile.selectALegalEntity' |cxTranslate}}"
          emptyMessage="{{'myProfile.noMatchingOptions' |cxTranslate}}">
          <ng-template pTemplate="selectedItem" let-selectedOption>
            <div class="flex align-items-center gap-2">
              <div>{{selectedOption.name}}</div>
            </div>
          </ng-template>
          <ng-template let-country pTemplate="item">
            <div class="flex align-items-center gap-2">
              <div>{{country.name}}</div>
            </div>
          </ng-template>
        </p-dropdown>
      </div>
    </div>
    <div class="col">
      <button class="btn cstm-btn-primary ml-2  pb-1" (click)="addNewEntryToTable()">
        {{'myProfile.add' | cxTranslate}}
      </button>
    </div>
  </div>
</div>

<!-- Modal -->
<div *ngIf="toasterSuccess">
  <div id="exampleModal" role="dialog" aria-labelledby="exampleModalLabel" class="modal fade cstm-padding"
    [ngClass]="{'show': toasterSuccess}" aria-modal="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title">{{"myProfile.confirmation" |cxTranslate}}</h1>
          <button type="button" (click)="toasterSuccess=false" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <h5 class="modal-title">{{toastMsg ==('Your Access Request(s) have been
            sent!')?("myProfile.cstmToastMsg"|cxTranslate):("myProfile.errorToastMsg"|cxTranslate)}}</h5>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn cstm-btn-primary" data-dismiss="modal"
            (click)="toasterSuccess =false">{{"myProfile.ok" |cxTranslate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>